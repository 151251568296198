import { Component } from 'react'
import { createEventEmitter } from 'medux/event'

import 'codemirror/lib/codemirror.css'
import '@tangible/codemirror/themes/light.scss'

// https://github.com/caplin/FlexLayout
import Paneler from '@tangible/paneler'
import '@tangible/paneler/themes/light.scss'

import { ExpressionResult, ExpressionInstructions } from 'features/ExpressionResults'

import layoutData from './layoutData'
import ExpressionEditor from 'features/ExpressionEditor'
import Overview from './overview'

import Prism from 'prism-react-renderer/prism'
import ScrollTop from 'features/ScrollTop'

class Article extends Component {

  componentDidMount() {
    this.el.tabIndex = '0'
    this.el.focus()

    this.eventHub = this.props.eventHub

    this.codes = Array.prototype.slice.call(this.el.querySelectorAll('code'))
    this.codes.forEach(e => {
      e.addEventListener('click', this.onClick)
      if (e.parentNode.tagName!=='PRE') {
        e.innerHTML = Prism.highlight(e.innerText, Prism.languages['expreva'], 'expreva')
      }
    })
  }

  componentWillUnmount() {
    this.codes.forEach(e => e.removeEventListener('click', this.onClick))
  }

  onClick = e => {
    const code = e.target.closest('code')
    if (!code) return
    console.log('render', code.innerText)
    this.eventHub.emit('exprevaEvaluate', { expression: code.innerText })
  }

  render() {
    return <div id="scroll-container" ref={el => this.el = el} style={{ overflow: 'auto', height: '100%', position: 'relative' }}>
      <Overview />
      <ScrollTop containerId="scroll-container" />
    </div>
  }
}

export default class extends Component {

  constructor(props) {
    super(props)
    this.model = Paneler.Model.fromJson(layoutData)
    this.eventHub = createEventEmitter()
    this.codeMirrorListeners = []
  }

  componentDidMount() {
    this.CodeMirror = window.CodeMirror = require('codemirror')
    // console.log('codemirror', CodeMirror)
    if (this.codeMirrorListeners.length) {
      this.codeMirrorListeners.forEach(fn => fn(this.CodeMirror))
    }
  }

  awaitCodeMirror = (fn) => {
    if (this.CodeMirror) return fn(this.CodeMirror)
    this.codeMirrorListeners.push(fn)
  }

  factory = (node) => {

    const component = node.getComponent()

    // Share state among nodes, so one can send content to another

    switch (component) {
    case 'Expression':
      return <ExpressionEditor awaitCodeMirror={this.awaitCodeMirror} eventHub={this.eventHub} />
    case 'Result':
      return <ExpressionResult eventHub={this.eventHub} />
    case 'Instructions':
      return <ExpressionInstructions eventHub={this.eventHub} />
    case 'Article':
      return <Article eventHub={this.eventHub} />
    }
  }

  onAdd = () => {

    // addTabWithDragAndDropIndirect, addTabToActiveTabSet
    this.refs.layout.addTabToTabSet('preview', {
      component: "text",
      name: "added",
      config: { text: "i was added" }
    }, null)
  }

  render() {
    return <Paneler.Layout
      ref="layout"
      model={this.model}
      factory={this.factory}
      // onModelChange={() => console.log(this.model.toJson())}
    />
  }
}
