import { Component } from 'react'
import { syntaxTreeToPrettyString, valueToPrettyString } from 'expreva/format'

export class ExpressionResult extends Component {

  state = {
    result: undefined,
    errorMessage: ''
  }

  unsubscribers = []

  componentDidMount() {
    const { eventHub } = this.props
    this.unsubscribers.push(
      eventHub.on('exprevaEvaluated', ({ result }) => {
        this.setState({
          result,
          errorMessage: undefined
        })
      }),
      eventHub.on('exprevaEvaluateError', ({ errorMessage }) => {
        this.setState({
          result: undefined,
          errorMessage
        })
      }),
    )
  }

  componentWillUnmount() {
    this.unsubscribers.forEach(f => f())
  }

  render() {
    const {
      result, errorMessage
    } = this.state
    return <pre className="expression-result-container">
      <code>{ errorMessage || valueToPrettyString(result) }</code>
    </pre>
  }
}

export class ExpressionInstructions extends Component {

  state = {
    parsed: []
  }

  unsubscribers = []

  componentDidMount() {
    const { eventHub } = this.props
    this.unsubscribers.push(
      eventHub.on('exprevaParsed', ({ parsed = [] }) => {
        this.setState({
          parsed: syntaxTreeToPrettyString(parsed)
        })
      })
    )
  }

  componentWillUnmount() {
    this.unsubscribers.forEach(f => f())
  }

  render() {
    return <pre className="expression-instructions-container">
      <code>{ this.state.parsed }</code>
    </pre>
  }
}
