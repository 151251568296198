
// https://github.com/caplin/FlexLayout

const layoutData = {
  global: {
    // tabSetEnableTabStrip:false,
    tabSetEnableMaximize: false,
    tabSetTabStripHeight: 22,
    splitterSize: 4, // 8
    enableEdgeDock: false,
  },
  borders: [
    // {
    //   type:'border',
    //   location: 'left',
    //   size: 260,
    //   barSize: 0,
    //   enableDrop: false,
    //   show: true,
    //   selected: 0,
    //   children: [
    //     {
    //       type: 'tab',
    //       name: 'Sidebar',
    //       component: 'Sidebar',
    //       enableClose: false,
    //       enableDrag: false,
    //       enableRename: false,
    //     }
    //   ]
    // },
  ],
  layout:{
    type: 'row',
    weight: 100,
    children: [
      // {
      //   type: 'tabset',
      //   weight: 25,
      //   selected: 0,
      //   enableTabStrip: false,
      //   enableDrag: false,
      //   enableDrop: false,
      //   enableDivide: false,
      //   children: [
      //     {
      //       type: 'tab',
      //       name: 'FX',
      //       component:'grid',
      //     }
      //   ]
      // },
      { type: 'row',
        weight: 30,
        width: 420,
        children: [
          {
            type: 'tabset',
            className: 'tabset-expression',
            weight: 33,
            selected: 0,
            enableDrag: false,
            enableDrop: false,
            enableDivide: false,
            children: [
              {
                type: 'tab',
                name: 'Expression',
                component:'Expression',
                enableClose: false,
                enableRename: false,
              }
            ]
          },
          {
            type: 'tabset',
            weight: 33,
            selected: 0,
            enableDrag: false,
            enableDrop: false,
            enableDivide: false,
            children: [
              {
                type: 'tab',
                name: 'Value',
                component:'Result',
                enableClose: false,
                enableRename: false,
              }
            ]
          },
          {
            type: 'tabset',
            weight: 33,
            selected: 0,
            enableDrag: false,
            enableDrop: false,
            enableDivide: false,
            children: [
              {
                type: 'tab',
                name: 'Instructions',
                component:'Instructions',
                enableClose: false,
                enableRename: false,
              }
            ]
          },
        ]
      },
      {
        type: 'tabset',
        weight: 50,
        selected: 0,
        enableTabStrip: false,
        enableDrag: false,
        enableDrop: false,
        enableDivide: false,
        children: [
          {
            type: 'tab',
            name: 'Article',
            component:'Article',
            enableClose: false,
            enableRename: false,
          }
        ]
      },
    ]
  }
}

export default layoutData