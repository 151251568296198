import { Component } from 'react'
import animateScroll from 'react-scroll/modules/mixins/animate-scroll'
import './index.scss'

export default class ScrollTop extends Component {

  containerId = this.props.containerId || 'scroll-container'
  activeClass = 'is-active'
  showAboveY = 0

  state = { isActive: false }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // Show if scrolled down
    this.container = document.querySelector(`#${this.containerId}`)
    if (!this.container) return
    this.container.addEventListener('scroll', this.scrollHandler.bind(this))
    this.scrollHandler()
  }

  componentWillUnmount() {
    if (!this.container) return
    this.container.removeEventListener('scroll', this.scrollHandler.bind(this))
  }

  scrollHandler() {
    const y = this.container.scrollTop

    if (y > this.showAboveY) {
      if (!this.state.isActive) {
        this.setState({ isActive: true })
      }
      return
    }
    if (this.state.isActive) {
      this.setState({ isActive: false })
    }
  }

  scrollToTop = () => animateScroll.scrollToTop({
    containerId: this.containerId,
    duration: 300,
    delay: 0,
    smooth: 'easeInOutQuad'
  })

  render() {

    return <div
      className={`scroll-top${this.state.isActive ? ` ${this.activeClass}` : ''}`}
      onClick={this.scrollToTop}
    ><span /></div>
  }
}
