
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = ({ children }) => <article className="px-3 pb-3" {...{ children }} />
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview"
    }}>{`Overview`}</h1>
    <p><strong parentName="p" {...{
        "className": "h4"
      }}>{`Expreva`}</strong>{` is a small programming language based on arithmetic and algebra.`}</p>
    <p>{`Its grammar and concepts are influenced by functional, list-processing, and scripting languages. It aims to be a modular, extensible language and cross-platform virtual machine.`}</p>
    <hr></hr>
    <p>{`Expreva is an exploration to design a suitable medium for end-user programming, for example: in a spreadsheet formula; for data transport or query protocol; and creation of dynamic hypertext documents and interactive textbooks.`}</p>
    <p>{`The source code is compiled to a JSON-serializable format, suitable for transfer over HTTP, WebSocket, or inter-process communication.`}</p>
    <p>{`The goal is to stay beginner-friendly, and simple enough to implement on a range of platforms.`}</p>
    <p>{`Currently there is a `}<a parentName="p" {...{
        "href": "https://github.com/expreva/expreva"
      }}>{`TypeScript implementation`}</a>{` that runs in the web browser and on Node.js servers; and one that runs on `}<a parentName="p" {...{
        "href": "https://github.com/expreva/expreva-php"
      }}>{`PHP`}</a>{`. An eventual plan is to compile to WebAssembly.`}</p>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <p>{`Click on an expression and see its result on the left side of the page.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`1 + 2 + 3`}</inlineCode></p>
    <p>{`Try typing some numbers in the expression box to see the result change.`}</p>
    <h2 {...{
      "id": "language-reference"
    }}>{`Language Reference`}</h2>
    <h3 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#symbol"
          }}>{`Symbol`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#number"
            }}>{`Number`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#boolean"
            }}>{`Boolean`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#operator"
            }}>{`Operator`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#string"
            }}>{`String`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#variable"
            }}>{`Variable`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#function"
          }}>{`Function`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#function-define"
            }}>{`Define`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#function-arguments"
            }}>{`Arguments`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#function-call"
            }}>{`Call`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#function-apply"
            }}>{`Apply`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#list"
          }}>{`List`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#list-item"
            }}>{`List Item`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#list-functions"
            }}>{`List Functions`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#object"
          }}>{`Object`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#object-functions"
            }}>{`Object Functions`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#advanced-operators"
          }}>{`Advanced Operators`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#compound-assignment"
            }}>{`Compound Assignment`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#spread-operator"
            }}>{`Spread Operator`}</a></li>
        </ul>
        {
          /*
           - [Future Plan](#future-plan)
           - [Comparison](#comparison)
           - [Group](#group)
           - [Statement](#statement)
           - [Conditional](#conditional)
           - [Comment](#comment)
          */
        }
      </li>
    </ul>
    <h3 {...{
      "id": "symbol"
    }}>{`Symbol`}</h3>
    <p>{`The basic building block of an expression is a symbol.`}</p>
    <p>{`A symbol is made of one or more of the following characters.`}</p>
    <pre><code parentName="pre" {...{}}>{`! " # $ % & ' ( ) * + , - . / : ;
< = > ? @ [ \\ ] ^ _ \` { | } ~ 0 1
2 3 4 5 6 7 8 9 A B C D E F G H I
J K L M N O P Q R S T U V W X Y Z
a b c d e f g h i j k l m n o p q
r s t u v w x y z
`}</code></pre>
    <p>{`You can also use characters from any language, like `}<inlineCode parentName="p">{`あいうえお`}</inlineCode>{`.`}</p>
    <p>{`Symbols are separated by a white space or new line.`}</p>
    <hr></hr>
    <p>{`A symbol can be one of the following types:`}</p>
    <ul>
      <li parentName="ul"><a href="#number">Number</a></li>
      <li parentName="ul"><a href="#boolean">Boolean</a></li>
      <li parentName="ul"><a href="#operator">Operator</a></li>
      <li parentName="ul"><a href="#string">String</a></li>
      <li parentName="ul"><a href="#variable">Variable</a></li>
    </ul>
    <h3 {...{
      "id": "number"
    }}>{`Number`}</h3>
    <p>{`Example: `}<inlineCode parentName="p">{`42`}</inlineCode>{` `}<inlineCode parentName="p">{`2.5`}</inlineCode></p>
    <h3 {...{
      "id": "boolean"
    }}>{`Boolean`}</h3>
    <p>{`There are two kinds of boolean symbols: `}<inlineCode parentName="p">{`true`}</inlineCode>{` and `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "operator"
    }}>{`Operator`}</h3>
    <p>{`An operator works with one or more expressions, and returns a value.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1 + 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtract`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3 - 4`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Multiply`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`5 * 6`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`/`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Divide`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7 / 8`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`^`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exponentiation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`9 ^ 10`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`=`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Assignment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = 12`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "comparison"
    }}>{`Comparison`}</h4>
    <p>{`These operators compare two expressions and return a boolean value.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Operator`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`==`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1 + 2 == 3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`!=`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Not equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1 + 2 != 3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`>`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greater than`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3 * 3 > 3 + 3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`<`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1 + 1 < 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`>`}{`=`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Greater than or equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2 * 2 >= 2 + 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`<`}{`=`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Less than or equal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3 + 3 <= 2 + 3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`&`}{`&`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`And`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`2 == 2 && 3 == 3`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`|`}{`|`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Or`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code>{`2 != 2 `}{`|`}{`|`}{` 3 != 3`}</code></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "group"
    }}>{`Group`}</h4>
    <p>{`The open and close parentheses `}<inlineCode parentName="p">{`()`}</inlineCode>{`  group expressions together.`}</p>
    <p>{`This is used to control the order of operations.`}</p>
    <p>{`See the difference between `}<inlineCode parentName="p">{`(1 + 2) * 3`}</inlineCode>{` and `}<inlineCode parentName="p">{`1 + (2 * 3)`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "statement"
    }}>{`Statement`}</h4>
    <p>{`A `}<em parentName="p">{`statement`}</em>{` is an expression that is not operating on any other expression.`}</p>
    <p>{`In the following, there are three statements.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = 2
y = x * 3
z = y * y * 4
`}</code></pre>
    <p>{`The result of multiple statements is the value of the last statement.`}</p>
    <hr></hr>
    <p>{`Statements are separated by a white space or new line.`}</p>
    <p>{`The exception is if a line starts or ends with an operator that connects to another expression.`}</p>
    <pre><code parentName="pre" {...{}}>{`1
+2
`}</code></pre>
    <p>{`Such cases are rare, but if needed, use the punctuation `}<inlineCode parentName="p">{`;`}</inlineCode>{` to end a statement.`}</p>
    <pre><code parentName="pre" {...{}}>{`1;
+2
`}</code></pre>
    <h4 {...{
      "id": "conditional"
    }}>{`Conditional`}</h4>
    <p>{`Use the operators `}<inlineCode parentName="p">{`?`}</inlineCode>{` and `}<inlineCode parentName="p">{`:`}</inlineCode>{` to create a conditional expression.`}</p>
    <p>{`It is used to control the flow of opearations based on a condition.`}</p>
    <p>{`Such an expression has 3 parts.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Condition`}</strong>{` that evaluates to `}<em parentName="li">{`true`}</em>{` or `}<em parentName="li">{`false`}</em></li>
      <li parentName="ul"><strong parentName="li">{`True`}</strong>{` branch to evaluate when true`}</li>
      <li parentName="ul"><strong parentName="li">{`False`}</strong>{` branch to evaluate when false`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`4 * 8 == 32 ? 'Yes' : 'No'
`}</code></pre>
    <h4 {...{
      "id": "comment"
    }}>{`Comment`}</h4>
    <p>{`A comment is any text you want to write that is not evaluated.`}</p>
    <p>{`It's useful for including extra information, for example, to explain how an expression works.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`//`}</inlineCode>{` to comment the rest of the line.`}</p>
    <pre><code parentName="pre" {...{}}>{`1 + 1 // Hello!
`}</code></pre>
    <p>{`Create a comment block spanning multiple lines using `}<inlineCode parentName="p">{`/*`}</inlineCode>{` and `}<inlineCode parentName="p">{`*/`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`/*

Here is a comment.

*/
`}</code></pre>
    <h3 {...{
      "id": "string"
    }}>{`String`}</h3>
    <p>{`A string is some text created with the punctuation `}<inlineCode parentName="p">{`'`}</inlineCode>{` called a single quote.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`'hi'`}</inlineCode></p>
    <p>{`When you click on the example, you see that the result does not include the punctuations.`}</p>
    <p><inlineCode parentName="p">{`'`}</inlineCode>{` is an operator that returns what's between them, called the `}<em parentName="p">{`value`}</em>{` of the string.`}</p>
    <h4 {...{
      "id": "escape-character"
    }}>{`Escape character`}</h4>
    <p>{`To include `}<inlineCode parentName="p">{`'`}</inlineCode>{` itself in the string, use another punctuation `}<inlineCode parentName="p">{`\\`}</inlineCode>{` before it.`}</p>
    <pre><code parentName="pre" {...{}}>{`'It\\'s a beautiful day in the neighborhood.'
`}</code></pre>
    <p><inlineCode parentName="p">{`\\`}</inlineCode>{` is called a backslash. It is used to `}<em parentName="p">{`escape`}</em>{` the character after it, making it a string instead of a punctuation.`}</p>
    <h3 {...{
      "id": "variable"
    }}>{`Variable`}</h3>
    <p>{`A variable is a name that points to another symbol.`}</p>
    <p>{`The name has one or more characters, alphabets and numbers only. It must start with an alphabet.`}</p>
    <p>{`Example: `}<inlineCode parentName="p">{`distance`}</inlineCode></p>
    <p>{`When you click on the above example, you see the result is empty. This is because the variable doesn't have any value yet.`}</p>
    <p>{`To make a variable point to a symbol, use the `}<inlineCode parentName="p">{`=`}</inlineCode>{` operator. This is called `}<em parentName="p">{`assigning`}</em>{` a value.`}</p>
    <pre><code parentName="pre" {...{}}>{`distance = 50
`}</code></pre>
    <p>{`The result of variable assignment is the value itself.`}</p>
    <p>{`Now, when you evaluate the variable `}<inlineCode parentName="p">{`distance`}</inlineCode>{`, it will return its value.`}</p>
    <hr></hr>
    <p>{`A variable name cannot have a space in it. Use the underscore `}<inlineCode parentName="p">{`_`}</inlineCode>{`to separate words.`}</p>
    <pre><code parentName="pre" {...{}}>{`total_distance = 100
`}</code></pre>
    <h3 {...{
      "id": "function"
    }}>{`Function`}</h3>
    <p>{`A function takes an input, evaluates an expression, and returns an output.`}</p>
    <h4 {...{
      "id": "function-define"
    }}>{`Define`}</h4>
    <p>{`Use the operator `}<inlineCode parentName="p">{`=>`}</inlineCode>{` to define a function.`}</p>
    <pre><code parentName="pre" {...{}}>{`x => x + 1
`}</code></pre>
    <p>{`A function without a name is called an `}<em parentName="p">{`anonymous`}</em>{` function.`}</p>
    <hr></hr>
    <p>{`Assign it to a variable to give the function a name.`}</p>
    <p>{`Usually a function name starts with a verb, because it performs an action.`}</p>
    <pre><code parentName="pre" {...{}}>{`add_one = x => x + 1
`}</code></pre>
    <p>{`This is useful if you plan to use it more than once.`}</p>
    <pre><code parentName="pre" {...{}}>{`add_one(3) + add_one(4)
`}</code></pre>
    <h4 {...{
      "id": "function-arguments"
    }}>{`Arguments`}</h4>
    <p>{`The left part of a function definition is a list of inputs, called `}<em parentName="p">{`arguments`}</em>{`.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`()`}</inlineCode>{` to pass arguments, separated by a comma.`}</p>
    <pre><code parentName="pre" {...{}}>{`(x, y) => x + y
`}</code></pre>
    <p>{`Here, `}<inlineCode parentName="p">{`x`}</inlineCode>{` and `}<inlineCode parentName="p">{`y`}</inlineCode>{` will be passed as inputs when the function is called.`}</p>
    <p>{`Each argument is assigned to a variable of that name, in the order given.`}</p>
    <hr></hr>
    <p>{`The argument list can be empty if the function doesn't need input.`}</p>
    <pre><code parentName="pre" {...{}}>{`() => 123
`}</code></pre>
    <p>{`If there's only one argument, the `}<inlineCode parentName="p">{`()`}</inlineCode>{` is not necessary.`}</p>
    <pre><code parentName="pre" {...{}}>{`x => x * x
`}</code></pre>
    <h4 {...{
      "id": "argument-defaults"
    }}>{`Argument Defaults`}</h4>
    <p>{`The assignment operator `}<inlineCode parentName="p">{`=`}</inlineCode>{` can be used to provide initial values for missing function arguments.`}</p>
    <pre><code parentName="pre" {...{}}>{`f = (x = 1, y = 2) => x * y
f(3)
`}</code></pre>
    <h4 {...{
      "id": "function-call"
    }}>{`Call`}</h4>
    <p>{`Call a function with `}<inlineCode parentName="p">{`()`}</inlineCode>{`, optionally with a list of arguments.`}</p>
    <pre><code parentName="pre" {...{}}>{`say_love = (x, y) => 'I love '+x+' and '+y+'!'

say_love('apples', 'bananas')
`}</code></pre>
    <p>{`Use `}<inlineCode parentName="p">{`()`}</inlineCode>{` to call an anonymous function immediately after creating.`}</p>
    <pre><code parentName="pre" {...{}}>{`(x => x + 1)(2)
`}</code></pre>
    <h4 {...{
      "id": "function-apply"
    }}>{`Apply`}</h4>
    <p>{`Another way to call a function is to `}<em parentName="p">{`apply`}</em>{` an expression to it.`}</p>
    <p>{`Use the operator `}<inlineCode parentName="p">{`->`}</inlineCode>{` after an expression to call a function with it as the first argument.`}</p>
    <pre><code parentName="pre" {...{}}>{`square = x => x * x

(3 + 3)->square
`}</code></pre>
    <p>{`Notice how the applied function doesn't need `}<inlineCode parentName="p">{`()`}</inlineCode>{` after it.`}</p>
    <p>{`This is equivalent to writing `}<inlineCode parentName="p">{`square(3 + 3)`}</inlineCode>{`.`}</p>
    <hr></hr>
    <p>{`The apply operator `}<inlineCode parentName="p">{`->`}</inlineCode>{` is like a pipeline, passing the result of an expression to the next function.`}</p>
    <p>{`It can make the flow of values easier to follow when chaining together multiple functions.`}</p>
    <pre><code parentName="pre" {...{}}>{`square = x => x * x
double = x => x * 2
triple = x => x * 3

3->square->double->triple
`}</code></pre>
    <p>{`You can see that the initial value is squared, doubled, then tripled.`}</p>
    <p>{`Compare to the following way of calling functions, where you must think "backwards" to visualize the flow of operations.`}</p>
    <pre><code parentName="pre" {...{}}>{`triple(double(square(3)))
`}</code></pre>
    <p>{`You can mix the two styles to organize an expression.`}</p>
    <pre><code parentName="pre" {...{}}>{`square(3)->double->triple
`}</code></pre>
    <h4 {...{
      "id": "using-functions"
    }}>{`Using functions`}</h4>
    <p>{`A function can be passed as an argument to another function.`}</p>
    <pre><code parentName="pre" {...{}}>{`[ 1, 2, 3 ]->map(x => x + 1)
`}</code></pre>
    <p>{`Or after an apply operator.`}</p>
    <pre><code parentName="pre" {...{}}>{`1 -> x => x + 1
`}</code></pre>
    <p>{`A function can return another function.`}</p>
    <pre><code parentName="pre" {...{}}>{`(x => y => x + y)(5)(6)
`}</code></pre>
    <h3 {...{
      "id": "list"
    }}>{`List`}</h3>
    <p>{`To create a list of items, use the open and close square brackets `}<inlineCode parentName="p">{`[]`}</inlineCode>{`.`}</p>
    <p>{`Use a comma `}<inlineCode parentName="p">{`,`}</inlineCode>{` to separate each item.`}</p>
    <pre><code parentName="pre" {...{}}>{`[ 1, 2, 3 ]
`}</code></pre>
    <p>{`A list can have any kind of item, including a function, the result of an expression, or another list.`}</p>
    <pre><code parentName="pre" {...{}}>{`[
  true,
  2 * 3 + 1,
  f = x => x * x,
  f(12),
  [ 'apples', 'oranges' ]
]
`}</code></pre>
    <h3 {...{
      "id": "list-item"
    }}>{`List Item`}</h3>
    <p>{`To get an item from a list, use a period `}<inlineCode parentName="p">{`.`}</inlineCode>{` after it, and the `}<em parentName="p">{`index`}</em>{` of the item.`}</p>
    <p>{`An `}<strong parentName="p">{`index`}</strong>{` is a number for the position of an item, counting from `}<strong parentName="p">{`zero`}</strong>{`.`}</p>
    <p>{`First, let's create a list and put it in a variable.`}</p>
    <pre><code parentName="pre" {...{}}>{`colors = [ 'red', 'green', 'blue' ]
`}</code></pre>
    <p>{`Then get items by their index.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`colors.0`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`colors.1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`colors.(1 + 1)`}</inlineCode></li>
    </ul>
    <p>{`The last example shows the index can be given as an expression.`}</p>
    <p>{`You can get an item from any expression that returns a list.`}</p>
    <pre><code parentName="pre" {...{}}>{`get_letters = () => ['a', 'b', 'c']
get_letters().1
`}</code></pre>
    <h3 {...{
      "id": "item-of-item"
    }}>{`Item of Item`}</h3>
    <p>{`If a list item is a list (or an object), you can combine multiple `}<inlineCode parentName="p">{`.`}</inlineCode>{` operators.`}</p>
    <pre><code parentName="pre" {...{}}>{`tic_tac_toe = [
  [ 'o', '.', '.' ],
  [ '.', 'x', '.' ],
  [ 'o', 'x', 'o' ]
]

tic_tac_toe.1.1
`}</code></pre>
    <h3 {...{
      "id": "assign-list-item"
    }}>{`Assign list item`}</h3>
    <pre><code parentName="pre" {...{}}>{`a = [ 0, 0 ]
a.1 = 1
a
`}</code></pre>
    <h4 {...{
      "id": "list-functions"
    }}>{`List Functions`}</h4>
    <p>{`There are a number of built-in functions for working with a list.`}</p>
    <ul>
      <li parentName="ul"><a href="#list-function-size">size</a>, <a href="#list-function-search">search</a>, <a href="#list-function-join">join</a></li>
      <li parentName="ul"><a href="#list-function-push">push</a>, <a href="#list-function-pop">pop</a>, <a href="#list-function-insert">insert</a>, <a href="#list-function-slice">slice</a></li>
      <li parentName="ul"><a href="#list-function-map">map</a>, <a href="#list-function-filter">filter</a>, <a href="#list-function-reduce">reduce</a></li>
    </ul>
    <p>{`Use the operator `}<inlineCode parentName="p">{`->`}</inlineCode>{` after a list to call these functions.`}</p>
    <pre><code parentName="pre" {...{}}>{`[ 1, 2, 3 ]->pop
`}</code></pre>
    <p>{`You can also call them with `}<inlineCode parentName="p">{`()`}</inlineCode>{` by passing a list as the first argument.`}</p>
    <pre><code parentName="pre" {...{}}>{`pop([ 4, 5, 6 ])
`}</code></pre>
    <p>{`Notice that some functions, for example `}<inlineCode parentName="p">{`search`}</inlineCode>{`, will return another function to be called with more arguments.`}</p>
    <h5 {...{
      "id": "list-function-size"
    }}>{`size`}</h5>
    <p><inlineCode parentName="p">{`size`}</inlineCode>{` returns the number of items in a list.`}</p>
    <pre><code parentName="pre" {...{}}>{`['red', 'green', 'blue']->size
`}</code></pre>
    <h5 {...{
      "id": "list-function-search"
    }}>{`search`}</h5>
    <p><inlineCode parentName="p">{`search`}</inlineCode>{` looks for an item in a list, and returns its position index.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`['red', 'green', 'blue']->search('blue')`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`['red', 'green', 'blue']->search('purple')`}</inlineCode></li>
    </ul>
    <p>{`If it can't find the item, it will return `}<inlineCode parentName="p">{`-1`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "list-function-join"
    }}>{`join`}</h5>
    <p><inlineCode parentName="p">{`join`}</inlineCode>{` puts two lists together and returns a new list.`}</p>
    <pre><code parentName="pre" {...{}}>{`['red', 'green', 'blue']->join(['white', 'black'])
`}</code></pre>
    <p>{`If given a string, it's used as a separator to combine all items into a string.`}</p>
    <pre><code parentName="pre" {...{}}>{`['red', 'green', 'blue']->join(' and ')
`}</code></pre>
    <hr></hr>
    <h5 {...{
      "id": "list-function-push"
    }}>{`push`}</h5>
    <p><inlineCode parentName="p">{`push`}</inlineCode>{` adds one or more items to the end of a list, and returns the list itself.`}</p>
    <pre><code parentName="pre" {...{}}>{`['white']->push('purple', 'pink')
`}</code></pre>
    <h5 {...{
      "id": "list-function-pop"
    }}>{`pop`}</h5>
    <p><inlineCode parentName="p">{`pop`}</inlineCode>{` removes an item from the end of a list, and returns the item.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3]->pop
`}</code></pre>
    <h5 {...{
      "id": "list-function-insert"
    }}>{`insert`}</h5>
    <p><inlineCode parentName="p">{`insert`}</inlineCode>{` adds one or more items to a list at a specified index, and returns the list.`}</p>
    <pre><code parentName="pre" {...{}}>{`[0, 1, 2, 3]->insert(2, 'Here is index 2')
`}</code></pre>
    <h5 {...{
      "id": "list-function-slice"
    }}>{`slice`}</h5>
    <p><inlineCode parentName="p">{`slice`}</inlineCode>{` gets a section of a list and returns a new list with those items.`}</p>
    <p>{`It takes two arguments: start index and end index.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3, 4 ,5]->slice(2, 4)
`}</code></pre>
    <p>{`If you don't pass an end index, it will get from start index to the end of the list.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3, 4 ,5]->slice(2)
`}</code></pre>
    <p>{`If the start index is negative, it will get from the end of the list.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3, 4 ,5]->slice(-2)
`}</code></pre>
    <hr></hr>
    <h5 {...{
      "id": "list-function-map"
    }}>{`map`}</h5>
    <p><inlineCode parentName="p">{`map`}</inlineCode>{` calls a given function with each item in the list, and returns a new list with what the function returned.`}</p>
    <pre><code parentName="pre" {...{}}>{`['red', 'green', 'blue']->map(
  (item, index) => (index + 1)+'. '+item
)
`}</code></pre>
    <p>{`It passes two arguments to the function, the item and its index.`}</p>
    <h5 {...{
      "id": "list-function-filter"
    }}>{`filter`}</h5>
    <p><inlineCode parentName="p">{`filter`}</inlineCode>{` calls a given function with each item in the list: if the function returns `}<inlineCode parentName="p">{`true`}</inlineCode>{`, it keeps the item. At the end, it returns a new list with those items.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3, 4, 5]->filter(
  (item, index) => item >= 3
)
`}</code></pre>
    <p>{`It passes two arguments to the function, the item and its index.`}</p>
    <h5 {...{
      "id": "list-function-reduce"
    }}>{`reduce`}</h5>
    <p><inlineCode parentName="p">{`reduce`}</inlineCode>{` takes a list and adds up a total of some type, such as a number, string, or list.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1, 2, 3, 4, 5]->reduce(
  (total, item) => total = total + item,
  0
)
`}</code></pre>
    <p><inlineCode parentName="p">{`reduce`}</inlineCode>{` takes two arguments:`}</p>
    <ul>
      <li parentName="ul">{`A function`}</li>
      <li parentName="ul">{`An initial value to use, called an `}<em parentName="li">{`accumulator`}</em>{`.`}</li>
    </ul>
    <p>{`In the above example, the accumulator starts with the value of zero.`}</p>
    <p>{`For each item in the list, the given function is called with the accumulator and the item. It's expected to return a new value for the accumulator. At the end, you get the final value.`}</p>
    <hr></hr>
    <h3 {...{
      "id": "object"
    }}>{`Object`}</h3>
    <p>{`An object is a kind of list where the items are in pairs, called a `}<em parentName="p">{`key`}</em>{` and a `}<em parentName="p">{`value`}</em>{`.`}</p>
    <p>{`It is sometimes called a `}<em parentName="p">{`dictionary`}</em>{`, because you "look up" a value by its key.`}</p>
    <p>{`To create an object, use the open and close curly brackets `}<inlineCode parentName="p">{`{}`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  type: 'dog',
  color: 'red'
}
`}</code></pre>
    <h4 {...{
      "id": "key-value-pair"
    }}>{`Key-value pair`}</h4>
    <p>{`Use a colon `}<inlineCode parentName="p">{`:`}</inlineCode>{` between key and value.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ key: 'value' }
`}</code></pre>
    <p>{`Use a comma `}<inlineCode parentName="p">{`,`}</inlineCode>{` to separate key-value pairs.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }
`}</code></pre>
    <h4 {...{
      "id": "key-as-string"
    }}>{`Key as string`}</h4>
    <p>{`If a key has a space in it, pass it as a string.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ 'key with space': 1 }
`}</code></pre>
    <h4 {...{
      "id": "dynamic-key-and-value"
    }}>{`Dynamic key and value`}</h4>
    <p>{`The key and value can be an expression.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = 'total'
{
  (x): 1 + 2
}
`}</code></pre>
    <p>{`Notice that `}<inlineCode parentName="p">{`()`}</inlineCode>{` is used to set the key to the value of the variable `}<inlineCode parentName="p">{`x`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "variable-as-key-and-value"
    }}>{`Variable as key and value`}</h4>
    <p>{`For setting a variable's name as key and its value to an object, you can skip the `}<inlineCode parentName="p">{`:`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`key = 'value'
{ key }
`}</code></pre>
    <h4 {...{
      "id": "value"
    }}>{`Value`}</h4>
    <p>{`To get a value, use the operator `}<inlineCode parentName="p">{`.`}</inlineCode>{` and the name of key after an object.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }.x
`}</code></pre>
    <p>{`To use a dynamic key, wrap it in `}<inlineCode parentName="p">{`()`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`key = 'z'
{ x: 1, y: 2, z: 3 }.(key)
`}</code></pre>
    <p>{`If the value is an object (or a list), you can combine multiple `}<inlineCode parentName="p">{`.`}</inlineCode>{` operators.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  parent: {
    child: 'here!'
  }
}.parent.child
`}</code></pre>
    <h4 {...{
      "id": "assign-value-to-key"
    }}>{`Assign value to key`}</h4>
    <pre><code parentName="pre" {...{}}>{`a = {
  b: {}
}
a.b.c = 'hi'
a
`}</code></pre>
    <h4 {...{
      "id": "object-functions"
    }}>{`Object Functions`}</h4>
    <p>{`There are a number of built-in functions for working with an object.`}</p>
    <ul>
      <li parentName="ul"><a href="#object-function-size">size</a>, <a href="#object-function-keys">keys</a></li>
      <li parentName="ul"><a href="#object-function-set">set</a>, <a href="#object-function-unset">unset</a></li>
    </ul>
    <p>{`Use the operator `}<inlineCode parentName="p">{`->`}</inlineCode>{` after an object to call these functions.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ a: 1, b: 2 }->keys
`}</code></pre>
    <p>{`You can also call them with `}<inlineCode parentName="p">{`()`}</inlineCode>{` by passing an object as the first argument.`}</p>
    <pre><code parentName="pre" {...{}}>{`keys({ c: 3, d: 4 })
`}</code></pre>
    <h5 {...{
      "id": "object-function-size"
    }}>{`size`}</h5>
    <p><inlineCode parentName="p">{`size`}</inlineCode>{` returns the number of keys in an object.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }->size
`}</code></pre>
    <h5 {...{
      "id": "object-function-keys"
    }}>{`keys`}</h5>
    <p><inlineCode parentName="p">{`keys`}</inlineCode>{` returns the keys in an object as a list.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }->keys
`}</code></pre>
    <hr></hr>
    <h5 {...{
      "id": "object-function-set"
    }}>{`set`}</h5>
    <p><inlineCode parentName="p">{`set`}</inlineCode>{` assigns a key and its value to an object, and returns the object.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }->set('a', 0)
`}</code></pre>
    <p>{`If you pass an object instead of a key, it will set all keys and their values.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }->set({ a: -1, b: -2 })
`}</code></pre>
    <h5 {...{
      "id": "object-function-unset"
    }}>{`unset`}</h5>
    <p><inlineCode parentName="p">{`unset`}</inlineCode>{` removes a key and its value from an object, and returns the object.`}</p>
    <pre><code parentName="pre" {...{}}>{`{ x: 1, y: 2, z: 3 }->unset('x')->unset('y')
`}</code></pre>
    <h3 {...{
      "id": "advanced-operators"
    }}>{`Advanced Operators`}</h3>
    <h4 {...{
      "id": "compound-assignment"
    }}>{`Compound Assignment`}</h4>
    <p>{`There are shortcuts for assigning a new value to a variable, based on its existing value.`}</p>
    <p>{`Let's say we start with `}<inlineCode parentName="p">{`x = 0`}</inlineCode>{`. Click on it for the examples below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Compound Assignment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Equivalent`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`++`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x++`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x + 1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x--`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x - 1`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`+=`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x += 2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x + 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-=`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x -= 2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x - 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`*=`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x *= 2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x * 2`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/=`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x /= 2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x = x / 2`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "spread-operator"
    }}>{`Spread operator`}</h4>
    <p>{`The spread operator `}<inlineCode parentName="p">{`...`}</inlineCode>{` is used to assign a list of values from a variable or expression.`}</p>
    <h5 {...{
      "id": "spread-list"
    }}>{`List`}</h5>
    <p>{`It can spread values into an list.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = [ 1, 2, 3 ]

[ 0, ...x, 4 ]
`}</code></pre>
    <h5 {...{
      "id": "spread-object"
    }}>{`Object`}</h5>
    <p>{`With objects, it spreads key-value pairs.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = { c: 3 }
{
  a: 1,
  b: 2,
  ...x
}
`}</code></pre>
    <p>{`If the same key exists, it will be overwritten with a new value.`}</p>
    <pre><code parentName="pre" {...{}}>{`x = { c: 3 }
y = { c: 4 }
{
  a: 1,
  b: 2,
  ...x,
  ...y
}
`}</code></pre>
    <h5 {...{
      "id": "spread-function-arguments"
    }}>{`Function arguments`}</h5>
    <p>{`When used in a function argument, it gathers a list of values into a variable.`}</p>
    <pre><code parentName="pre" {...{}}>{`f = (x, ...y) => y

f(1, 2, 3)
`}</code></pre>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;