import { Component } from 'react'
import * as expreva from '../expreva'
import * as exprevaCodemirror from '../expreva/extend/codemirror'

export default class ExpressionEditor extends Component {

  unsubscribers = []

  componentDidMount() {

    const {
      awaitCodeMirror,
      eventHub
    } = this.props

    const exprevaEnvironment = expreva.createEnvironment({})

    awaitCodeMirror(CodeMirror => {

      exprevaCodemirror.defineMode(CodeMirror, expreva)

      const editor = CodeMirror.fromTextArea(this.el, {
        theme: 'tangible-light',
        value: '',
        mode: {
          name: 'expreva', // text/x-scss text/html
        },
        // gutters: ['CodeMirror-lint-markers'],
        lint: {
          esversion: 6,
          selfContain: true
        },
        // lineWrapping: true,
        spellcheck: false,
        viewportMargin: Infinity, // With .CodeMirror height: auto or 100%

        // autofocus: true,
        lineNumbers: false,
        matchBrackets: true,
        tabSize: 1,
        indentUnit: 1,
        indentWithTabs: false,

        autoCloseBrackets: true,
        // TODO: Resolve a few issues with color picker
        // colorpicker : {
        //   mode : 'edit'
        // },

        extraKeys: {
          //'Tab': 'emmetExpandAbbreviation',
          'Ctrl-S': function(cm) {
            console.log('Save')
          },
          'Cmd-S': function(cm) {
            console.log('Save')
          },
          // 'Enter': 'emmetInsertLineBreak',
          Tab: function(cm, ctx) {
            // var result = CodeMirror.commands.emmetExpandAbbreviation(cm, ctx)
            // if (result===true) return
            var spaces = Array(cm.getOption('indentUnit') + 1).join(' ')
            cm.replaceSelection(spaces)
          },
          'Ctrl-Space': 'autocomplete'
        },

      })

      exprevaCodemirror.registerLintHelper(CodeMirror, editor)

      // Workaround to fix CM bug with initial content and cursor
      setTimeout(() => editor.refresh(), 0)

      this.editor = editor

      const onChange = () => {
        const content = editor.getValue()
        try {
          const parsed = expreva.parse(content)

          console.log('parsed', parsed)
          eventHub.emit('exprevaParsed', {
            parsed,
          })

          const result = expreva.evaluate(parsed, exprevaEnvironment)

          console.log('evaluated', result)
          eventHub.emit('exprevaEvaluated', {
            result
          })
        } catch(e) {
          // console.log(e.message)
          eventHub.emit('exprevaEvaluateError', {
            errorMessage: e.message
          })
        }
      }

      editor.on('change', onChange)

      this.unsubscribers.push(
        eventHub.on('exprevaEvaluate', ({ expression }) => {
          editor.setValue(expression)
        })
      )

      onChange()
    })
  }

  componentWillUnmount() {
    this.unsubscribers.forEach(f => f())
  }

  render() {
    return <div className="expression-editor-container"
      onClick={() => this.editor && this.editor.focus()}
    >
      <textarea
        className="expression-editor"
        ref={el => this.el = el}
        rows="10"
        defaultValue={`((x, y, z) => x + y + z)(4, 5, 6)`}
      ></textarea>
    </div>
  }
}
